<template>
<div class="v-family-number" id="numbers">
  <div class="numbers-head"> Бронирование номеров производится по предоплате в размере 20% от суммы заказа. (Предоплата не возвращается)</div>
  <div class="family-PC">
  <!-- ------------------------------- -->
    <div class="number-row">
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">Уют</div>
          <Yut class="mini-gallery"/>
          <div class="card-text">Cтоимость одного часа <b>1 600 руб.</b> вместимость 4 человека, минимальный заказ 2 часа. </div>
        </div>
      </div>
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">Комфорт</div>
          <Comfort class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>1 800 руб.</b> вместимость 4 человека, минимальный заказ 2 часа. </div>
        </div>
      </div>
    </div>
  <!-- ------------------------ -->
    <div class="number-row">
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">Эрчим</div>
          <Erchim class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>2 000 руб.</b> вместимость 6 человек, минимальный заказ 2 часа.  </div>
        </div>
      </div>
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">Русский дом</div>
          <RussianHouse class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>2 800 руб.</b> вместимость 8 человек, минимальный заказ 2 часа. </div>
        </div>
      </div>  
    </div>
    <!-- ----------------- -->
    <div class="number-row">
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">Берлога</div>
          <Berloga class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>2 900 руб.</b> вместимость 8 человек, минимальный заказ 2 часа. </div>
        </div>
      </div>
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">Семейный</div>
          <Family class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>2 400 руб.</b> вместимость 6 человек, минимальный заказ 2 часа.  </div>
        </div>
      </div>
    </div>
    <!-- -------------------- -->
    <!-- ----------------- -->
    <div class="number-row">
      <div class="number-col">
        <div class="number-card">
          <div class="card-zag">VIP</div>
          <VIP class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>3 000 руб.</b> вместимость 8 человек, минимальный заказ 2 часа.  </div>
        </div>
      </div>
    </div>
    <!-- -------------------- -->
  </div>
  <div class="family-mobile">
    <div class="number-col">
      <div class="number-card">
        <div class="card-zag">Уют</div>
        <Yut class="mini-gallery"/>
        <div class="card-text">Cтоимость одного часа <b>1 600 руб.</b> вместимость 4 человека, минимальный заказ 2 часа. </div>
      </div>
    </div>
    <div class="number-col">
      <div class="number-card">
          <div class="card-zag">Комфорт</div>
          <Comfort class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>1 800 руб.</b> вместимость 4 человека, минимальный заказ 2 часа. </div>
      </div>
    </div>
    <div class="number-col">
      <div class="number-card">
          <div class="card-zag">Эрчим</div>
          <Erchim class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>2 000 руб.</b> вместимость 6 человек, минимальный заказ 2 часа.  </div>
      </div>
    </div>
    <div class="number-col">
      <div class="number-card">
          <div class="card-zag">Русский дом</div>
          <RussianHouse class="mini-gallery"/>
          <div class="card-text">стоимость одного часа <b>2 800 руб.</b> вместимость 8 человек, минимальный заказ 2 часа. </div>
      </div>
    </div>
    <div class="number-col">
      <div class="number-card">
        <div class="card-zag">Берлога</div>
        <Berloga class="mini-gallery"/>
        <div class="card-text">стоимость одного часа <b>2 900 руб.</b> вместимость 8 человек, минимальный заказ 2 часа. </div>
      </div>
    </div>
    <div class="number-col">
      <div class="number-card">
        <div class="card-zag">Семейный</div>
        <Family class="mini-gallery"/>
        <div class="card-text">стоимость одного часа <b>2 400 руб.</b> вместимость 6 человек, минимальный заказ 2 часа.  </div>
      </div>
    </div>
    <div class="number-col">
      <div class="number-card">
        <div class="card-zag">VIP</div>
        <VIP class="mini-gallery"/>
        <div class="card-text">стоимость одного часа <b>3 000 руб.</b> вместимость 8 человек, минимальный заказ 2 часа.  </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import Yut from '@/components/gallerys/yut-gallery.vue'
import Comfort from '@/components/gallerys/comfort-gallery.vue'
import Erchim from '@/components/gallerys/erchim-gallery.vue'
import RussianHouse from '@/components/gallerys/russian-house-gallery.vue'
import Berloga from '@/components/gallerys/berloga-gallery.vue'
import Family from '@/components/gallerys/family-gallery.vue'
import VIP from '@/components/gallerys/vip-gallery.vue'
export default {
  name: 'family-number',
  components: {
    Yut,
    Comfort,
    Erchim,
    RussianHouse,
    Berloga,
    Family,
    VIP
  }
}
</script>
<!-- background-color: #af7328a1; -->
<style>
.v-family-number{
  border: 5px solid #121820;
  background-image: url(@/images/test_fon.png) ;
  background-size: 100%;
}
.numbers-head {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  border-bottom: 5px solid #121820;
  color: black;
  background-color: white;
  margin: auto;
  text-align: center;
  font-size: 22px;
}
.mini-gallery{
  margin: 10px;
  border-radius: 10px;
}
.family-PC{
  display: block;
}
.family-mobile{
  display: none;
}
.number-row{
  width: 100%;
  height: 500px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.number-col{
  width: 50%;
  display: inline-block;
  height: 500px;
}
.number-card{
  border-radius: 20px;
  background-color: #f3f3f3;
  margin-top: 10px;
  box-shadow: 4px 4px 8px #121820;
  width: 80%;
  margin: auto;
  height: 95%;
  overflow: hidden;
}
.card-zag{
  margin-top: 20px;
  font-size: 40px;
  text-align: center;
}
.card-text{
  font-size: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
.number-col{
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
}
.number-card{
  width: 90%;
  height: 110%;
}
.family-PC{
  display: none;
}
.family-mobile{
  display: block;
}
}

@media screen and (min-width: 701px) and  (max-width: 1100px){
.number-col{
  display: block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.number-card{
  width: 90%;
  height: 99%;
}
.family-PC{
  display: none;
}
.family-mobile{
  display: block;
}
}
</style>
